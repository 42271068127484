import { createContainer } from 'unstated-next';
import { useState, useEffect } from 'react';
import { GetObeDetailsResult, Agreement } from 'api';
import useGetObeDetails from 'hooks/vehicle/useGetObeDetails';
import { VehicleRegistrationInformation } from 'common/types/vehicles.d';
import TransferPageSteps from 'modules/TransferTag/TransferPageSteps';
import SearchVehicleStore from 'store/vehicle/useSearchVehicle';
import IAttachmentFile from 'common/interfaces/IFileAttachment';

const useTransferTag = () => {
  const [getObe] = useGetObeDetails();
  const { searchVehicle } = SearchVehicleStore.useContainer();
  const [fromVehicle, setFromVehicle] = useState<GetObeDetailsResult | null>(
    null
  );

  const [toVehicle, setToVehicle] = useState<VehicleRegistrationInformation>(
    null
  );

  const [isForwardDeactivated, setIsForwardDeactivated] = useState<boolean>(
    true
  );

  const [agreement, setAgreement] = useState<Agreement | string>('');

  const [fileAttachment, setFileAttachment] = useState<IAttachmentFile | null>(
    null
  );

  const [pageStep, setPageStep] = useState<TransferPageSteps>(
    TransferPageSteps.ChooseFromVehicle
  );

  const getTagById = async (id: string) => {
    const obeResult: GetObeDetailsResult | null = await getObe(id);
    setFromVehicle(obeResult);
  };

  useEffect(() => {
    if (pageStep === TransferPageSteps.ProceedOrder) {
      setAgreement('');
    }
  }, [pageStep]);

  // Sets forwardButton deactivation
  useEffect(() => {
    if (pageStep === TransferPageSteps.ChooseFromVehicle && fromVehicle)
      return setIsForwardDeactivated(false);

    if (pageStep === TransferPageSteps.ChooseToVehicle && searchVehicle) {
      return setIsForwardDeactivated(false);
    }

    if (pageStep === TransferPageSteps.ProceedOrder && agreement)
      return setIsForwardDeactivated(false);

    return setIsForwardDeactivated(true);
  }, [fromVehicle, pageStep, searchVehicle, agreement]);

  return {
    toVehicle,
    fromVehicle,
    setFromVehicle,
    setToVehicle,
    isForwardDeactivated,
    pageStep,
    setPageStep,
    getTagById,
    agreement,
    setAgreement,
    fileAttachment,
    setFileAttachment
  };
};

export default createContainer(useTransferTag);
