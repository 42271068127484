{
  "title": "Min Side",
  "buttons": {
    "go_back": "Tilbake",
    "go_forward": "Neste",
    "back": "Tilbake",
    "back_button_arial_label": "Gå tilbake til forrige side",
    "see_more": "Se mer",
    "submit": "Send",
    "cancel": "Avbryt",
    "download": "Last ned",
    "see_details": "Se detaljer",
    "back_to_main_page": "Tilbake til forsiden",
    "see_more_details": "Se mer detaljer",
    "go_to_delivery": "Gå til levering",
    "continue": "Gå videre",
    "previous": "Gå tilbake",
    "order": "Bestill",
    "edit_vehicle": "Rediger kjøretøy",
    "save": "Lagre",
    "edit": "Rediger",
    "remove_vehicle": "Fjern kjøretøy",
    "register_new": "Registrer ny",
    "close": "Lukk",
    "add_to_register": "Velg et kjøretøy du vil fjerne",
    "remove_from_list": "Fjern fra listen",
    "complete": "Fullfør",
    "save_edits": "Lagre endringer",
    "remove": "Fjern",
    "export_passings_list": "Eksporter passeringsliste",
    "understand": "Jeg skjønner",
    "search": "Søk"
  },

  "validation": {
    "only_string": "Kan bare inneholde bokstaver, mellomrom, - eller .",
    "no_special_characters": "Kan ikke bestå av noen spesialtegn bortsett fra bindestrek, mellomrom og sifre",
    "exclamation_no_special_characters": "Kan ikke bestå av noen spesialtegn bortsett fra bindestrek, utropstegn, mellomrom og sifre",
    "must_have_one_char": "Må inneholde minst ett tegn",
    "must_have_less_than_two_char": "Kan ikke ha mer enn to tegn",
    "too_many_chars": "For mange tegn",
    "cannot_be_negative_value": "Kan ikke ha negative tall",
    "only_numbers": "Kan bare inneholde tall",
    "578_obe_number_not_in_use": "Brikker som starter med 578 er ikke lenger gyldig",
    "only_positive_numbers": "Kan bare inneholde positive tall",
    "year": "Må være et firesifret år",
    "contains_sixteen_numbers": "Må inneholde 16 tall",
    "email_format": "E-post må ha e-postformat og inneholde '@'",
    "confirm_email_format": "De to emailene må være identiske",
    "phone_number": "Nummeret er ugyldig",
    "address": "Adresse kan bestå av bokstaver og husnummer",
    "no_numbers": "Kan ikke inneholde tall",
    "registration_number": "Må være fylt ut - Maks 10 tegn",
    "date_format": "Datoen er ikke riktig",
    "postal_city_only_characters": "Kan bare inneholde bokstaver og mellomrom.",
    "postal_code": "Dette postnummeret eksisterer ikke"
  },

  "titles": {
    "overview": "Oversikt",
    "choose_customers": "Kundeforhold",
    "vehicles_and_tag": "Mine kjøretøy og brikker",
    "invoices": "Fakturaer",
    "invoice": "Faktura",
    "passings": "Passeringer",
    "agreements": "Avtaler",
    "home": "Hjem",
    "help": "Hjelp",
    "cases": "Saker"
  },

  "profile_menu": {
    "profile": "Innstillinger",
    "customers": "Kundeforhold",
    "language": "Språk",
    "languages": {
      "norwegian": "Norsk",
      "english": "Engelsk"
    },
    "contact_us": "Kontakt oss",
    "logout": "Logg ut"
  },

  "datepicker": {
    "start_date": "Startdato",
    "end_date": "Sluttdato",
    "show_period": "Vis periode",
    "period_labels": {
      "thirty_days": "30 dager",
      "custom_defined": "Egendefinert"
    },
    "error_custom_max_one": "Maks tillatt å velge {{numberMonths}} måned",
    "error_custom_max_other": "Maks tillatt å velge {{numberMonths}} måneder"
  },

  "pagination": {
    "page": "Side",
    "of": "av",
    "first_page": "Første side",
    "previous_page": "Forrige",
    "next_page": "Neste",
    "last_page": "Siste side"
  },

  "agreement_status": {
    "New": "Ny",
    "Active": "Aktiv",
    "Inactive": "Inaktiv",
    "Frozen": "Frossen",
    "AwaitingApproval": "Venter på godkjenning",
    "ApprovedAwaitingShipping": "Venter på forsendelse",
    "Rejected": "Avvist",
    "Cancelled": "Avbrutt"
  },

  "form_file_input": {
    "error_loading_attachment": "Feil ved lasting av filen",
    "file_size_limit": "Denne filen er for stor til å lastes opp. Maksimal filstørrelse som støttes er {{fileSizeLimit}}MB.",
    "upload_file": "Last opp fil",
    "upload_in_progress": "Lasting av vedlegg pågår. Prøv igjen senere.",
    "compression_failed": "Komprimering av bilde feilet. Prøv igjen med en mindre fil, eller annen filtype.",
    "compressed_still_above_limit": "Forsøkte å komprimere filen, men resultatet var fortsatt større enn grensen på {{fileSizeLimit}}MB."
  },

  "home": {
    "title": "Hjem",
    "welcome": "Velkommen!",
    "overview": "Oversikt",
    "agreements": "Avtaler",
    "passings": "Passeringer",
    "invoices": "Faktura",
    "make_changes": "Gjør endringer",
    "new_vehicle": "Ny bil",
    "remove_a_vehicle": "Fjern en bil",
    "move_obe": "Flytt brikke"
  },

  "agreements": {
    "agreement": "Avtale",
    "agreements": "Avtaler",
    "introduction": "Nedenfor finner du en oversikt over avtalene dine. Når du velger en avtale kan du se de bilene som avtalen gjelder for. Du mottar en faktura for hver avtale du har.",
    "click_here_faq": "Klikk her for å lese ofte stilte spørsmål om avtaler",
    "overview": {
      "title": "Avtaleoversikt",
      "information": "Klikk på en avtale fra listen under for å se informasjon om hvilke biler avtalen gjelder for. Du kan søke på registrerings-, avtale- og brikkernummer.",
      "list_info": "Klikk på en avtale for mer informasjon",
      "balance": "Sum (NOK)",
      "search": "Søk",
      "click_row_for_more": "Klikk på raden for å vise mer detailjer om avtalen"
    },
    "make_changes": {
      "title": "Gjør endringer",
      "add_vehicle": "Registrer ny bil",
      "transfer_tag": "Flytt brikke",
      "remove_vehicle": "Fjern en bil",
      "order_empty_tag": "Bestill blanke brikker",
      "register_empty_tag": "Registrer blank brikke"
    },
    "obe_monthly_rent": "Brikkeabonnement per måned",
    "order": "Bestill",
    "agreement_status": "Avtalestatus",
    "create_agreement": "Opprett avtale",
    "agreement_type": "Avtaletype",
    "subtitle_1": "Her kan du opprette en ny avtale som må bli manuelt godkjent av FUS før den får aktiv status",
    "subtitle_2": "Avtaler som venter på godkjenning kan bli funnet i listen av avtaler.",
    "agreement_name": "Avtalenavn",
    "name": "Navn",
    "created_at": "Opprettet",
    "last_changed": "Sist endret",
    "total_vehicles": "Antall kjøretøy",
    "no_unpaid_invoices": "Du har ingen ubetalte fakturaer",
    "unpaid_invoices_prefix": "Du har ",
    "unpaid_invoices_one": "{{count}} ubetalt faktura (Klikk her for å se den)",
    "unpaid_invoices_other": "{{count}} ubetalte fakturaer (Klikk her for å se dem)",
    "no_agreement": "Ingen tilgjengelig avtale",
    "no_agreements": "Ingen tilgjengelige avtaler",
    "change_agreement_name": "Bytt avtalenavn",
    "change_agreement_description": "For å bytte avtalenavn, skriv inn ønsket avtalenavn og trykk godta.",
    "agreement_details": {
      "balance": "Saldo",
      "vehicles": "Kjøretøy",
      "search": "Finn avtale, kjøretøy eller brikke",
      "no_vehichles": "Ingen tilgjengelige kjøretøy"
    },
    "remove_a_vehicle": "Fjern et kjøretøy",
    "transfer_a_tag": "Flytt en brikke",
    "register_blank_obe": "Registrer blanke brikker",
    "choices_for_vehicles_and_tags": "Valg for kjøretøy og brikker",
    "get_started": "Kom i gang",
    "do_you_need_tag": "Trenger du bombrikke på et kjøretøy?",
    "register_new_vehicle": "Registrer nytt kjøretøy",
    "order_goods": "Bestilling av varer",
    "go_to_order_empty_tag": "Gå til bestilling av blanke brikker",
    "download_documentation": "Dokumentasjon",
    "download_documentation_arial_label": "Klikk knappen for å laste ned dokumentasjon til kjøretøyet",
    "exclude_inactive_agreements": {
      "button_active": "Ekskluder inaktive",
      "button_inactive": "Inkluder inaktive",
      "loading": "Laster innhold"
    }
  },

  "bank_reference": {
    "page_title": "Godkjenn tilgang",
    "helper_text_header": "Du gir nå tilgang til {{Sparebank}} for å behandle informasjon om dine:",
    "consent_1": "Bompasseringer og fergeturer",
    "consent_2": "Betaling og saldo",
    "consent_3": "Bompengeavtaler og biler",
    "reference_id": "Bank reference",
    "button_accept": "Godkjenn",
    "button_reject": "Avvis",
    "success_msg": "Your response has been saved",
    "error_msg": "Something went wrong, try again",
    "current_status": "Nåværende status: ",
    "accepted": "Godkjent",
    "rejected": "Avvist",
    "cancelled": "Kansellert",
    "not_approved": "Ikke godkjent",
    "missing_params_feedback_p1": "En feil oppstod under videresendingen fra SpareBank 1 - mangler parametere.",
    "missing_params_feedback_p2": "Vennligst gå tilbake til SpareBank 1 sin side og prøv igjen, eller prøv å lim den oppgitte URL'en i nettleseren igjen.",
    "missing_customer_profile_p1": "Denne siden er kun tilgjengelig dersom du har en gyldig konto.",
    "missing_customer_profile_p2": "Sørg for at du er logget på en bruker med en konto, eller opprett et kundeforhold først."
  },

  "select_agreement": {
    "agreement_invoice": "Hvordan ønsker du at brikken/kjøretøyet skal faktureres?",
    "existing_agreement_option": "På eksisterende fakturareferanse",
    "new_agreement_option": "Opprett ny fakturareferanse",
    "select_existing_agreement": "Velg eksisterende avtale / fakturareferanse",
    "select_new_agreement": "Skriv inn navn på ny avtale / fakturareferanse",
    "agreement_invoice_reference": "Avtale / Fakturareferanse"
  },

  "vehicles": {
    "vehicle_details": "Kjøretøydetaljer",
    "vehicle": "Kjøretøy",
    "nationality": "Nasjonalitet",
    "registration_number": "Registreringsnummer",
    "brand": "Merke",
    "model": "Modell",
    "color": "Farge",
    "classification": "Klassifisering",
    "search": "Søk",
    "weight": "Vekt",
    "size": "Størrelse",
    "heavy": "Tungt",
    "regular": "Lett",
    "euClass": "EUkode",
    "fuelType": "Drivstofftype",
    "co2": "Co2",
    "size_choice": {
      "under": "Inntil 3500 kg",
      "over": "Over 3500 kg"
    },
    "chargeable": "Ladbar",
    "chargable_choices": {
      "yes": "Ja",
      "no": "Nei"
    },
    "documentation": "Dokumentasjon",
    "documentation_info": "Ettersom kjøretøyet ikke er registrert i Norge, må du laste opp dokumentasjon for at den oppgitte informasjonen er korrekt, f.eks. vognkort. Tillate filformater er .doc, .docx, .pdf, .png og .jpeg",
    "foreign_vehicle_documentation": "Dokumentasjon til utenlandsk kjøretøy",
    "registered": "Registrert",
    "confirm_info": "Jeg bekrefter at alle kjøretøydetaljer er riktige.",
    "add_vehicle": "Legg til kjøretøy",
    "no_vehicle": "Ingen informasjon om kjøretøy funnet",
    "optional": "Valgfri",
    "production_year": "Årsmodell",
    "is_not_owner": "Sjekk at informasjonen om kjøretøyet er korrekt",
    "business_is_not_owner": "Sjekk at informasjonen om kjøretøyer er korrekt",
    "vehicle_not_found": "Kjøretøyet med dette registreringsnummeret ble ikke funnet",
    "no_attached_vehicle": "Ingen tilknytning til kjøretøy",
    "vehicle_pricing_warning": "Vær obs på at feil valg av verdier kan resultere i feil pris per passering.",
    "errors": {
      "co2_error": "CO2 feltet må være et tall mellom 0 og 99",
      "classification_error": "Klassifiseringsfelt må utfylles",
      "fuelType_error": "Drivstoffstypefelt må utfylles",
      "euClass_error": "EU kode må utfylles",
      "size_error_p1": "Feil størrelse basert på klassifikasjon og/eller EU kode valgt.",
      "size_error_p2": "Du må enten endre klassifikasjon, EU kode eller vektklasse."
    },
    "eucode_missing_message": "Vi fant ikke EU-kode for ditt kjøretøy. Vennligst angi riktig EU-kode her. Informasjon om EU-kode står oppgitt i vognkortet.",
    "isRegistered": "Denne bilen er fortsatt aktiv på en eksiterendebrikke. Trykk neste hvis du ønsker at bilen skal knyttes til ny brikke. Den eksisterende avtalen blir da avsluttet.",
    "available": "Bilen er tilgjengelig"
  },

  "invoices": {
    "unpaid_invoices": " ubetalte fakturaer",
    "page_details": "På denne siden finner du informasjon om dine fakturaer. Klikk på fakturadetaljer for mer informasjon, eller for å laste ned pdf.",
    "filtering": {
      "title": "Velg mellom ubetalte eller betalte fakturaer",
      "details_part_1": "Velg mellom å se ubetalte eller betalte fakturaer.",
      "details_part_2": "Husk at det kan ta noen dager etter betaling før fakturaen vises som betalt. Du kan søke på fakturanummer eller KID for å finne en bestemt faktura.",
      "unpaid_invoices_button": "Ubetalte",
      "unpaid_btn_aria_label": "Klikk for å vise ubetalte fakturaer",
      "paid_invoices_button": "Betalte",
      "paid_btn_aria_label": "Klikk for å vise betalte fakturaer",
      "remittance_button": "Tilbakebetalinger",
      "remittance_btn_aria_label": "Klikk for å se på tilbakebetalinger",
      "period_title": "Velg periode"
    },
    "search": {
      "title": "Søk fakturanummer",
      "placeholder": "Søk"
    },
    "invoice_details_button": "Fakturadetaljer",
    "invoice_details_button_aria_label": "Klikk for mer fakturadetaljer",
    "invoice_details_download_button": "Last ned",
    "download_as_csv": "Last ned fakturaer som CSV",
    "no_invoice": "Ingen tilgjengelige fakturaer",
    "invoices": "Fakturaer",
    "invoice": "Fakturadetaljer",
    "invoice_number": "Fakturanummer",
    "invoice_info": "Fakturainformasjon",
    "invoice_status": "Status",
    "invoice_agreement": "Avtale",
    "expiration_date": "Utløpsdato",
    "due_date": "Forfallsdato",
    "kr": "Kr.",
    "balance": "Balanse",
    "timespan": "Tidsramme",
    "view_passings": "Se passeringer",
    "view_invoice_info": "Se fakturadetaljer og passeringer",
    "no_available_invoice_details": "Ingen tilgjengelig informasjon om faktura",
    "no_available_invoice_details_pop_up": "Faktura mangler data for å presenteres. Vennligst kontakt kundeservice.",
    "invoice_date": "Fakturadato",
    "amount": "Beløp ({{currency}})",
    "kid": "KID",
    "invoice_lines": "Fakturaen gjelder",
    "quantity": "Antall",
    "description": "Beskrivelse",
    "no_invoice_lines": "Ingen tilgjengelige fakturalinjer",
    "total": "Total",
    "contact_debt_collector": "Kontakt inkassobyrå for mer informasjon",
    "history": "Fakturahistorikk",
    "history_info": "I oversikten under vises fakturaer og eventuelle betalingspåminnelser.",
    "types": {
      "table_header": "Fakturatype",
      "debt_collection": "Betalingsoppfordring",
      "debt_collection_notice": "Inkassovarsel",
      "invoice": "Faktura",
      "credit_note_distributed": "Kreditnota distribuert",
      "missing_invoice_type": "Mangler fakturatype: {{invoiceType}}"
    },
    "number": "Fakturanummer",
    "download_version_button": "Last ned faktura",
    "download_version_button_arial_label": "Klikk for å laste ned fakturaversjon",
    "no_history_found": "Ingen fakturahistorikk å vise",
    "error_fetching_invoice_versions": "En feil oppstod ved henting av fakturahistorikk",
    "error_downloading_invoice_version": "En feil oppstod ved nedlasting av fakturaversjon",
    "history_table_caption": "En tabell som inneholder hver fakturaversjon som har blitt sendt til kunden, samt en knapp per rad for å laste ned hver spesifikk fakturaversjon",
    "archived_disclaimer": {
      "title": "Merk",
      "text": "Passeringsfakturalinjene for denne fakturaen har blitt arkivert.",
      "button_text": "Last ned arkivert faktura"
    }
  },

  "orders": {
    "type": {
      "obe": "Brikke",
      "obe_holder": "OBE Holder",
      "blank_obe": "Blank brikkke"
    },
    "info_section_titles": {
      "order_date": "Bestillingsdato",
      "product_type": "Produkttype",
      "quantity": "Mengde",
      "reg_number": "Kjøretøyets registreringsnummer",
      "reg_country": "Kjøretøyets registreringsland"
    },
    "pending_orders": "Dine ventende ordre",
    "load_more": "Laster mer",
    "no_pending_orders": "Ingen ventende ordre"
  },

  "legacy_invoices": {
    "note": "Merk",
    "note_info_first": "På grunn av migrering til et nytt system har dine fakturaer",
    "note_info_second": "som er betalt før 1 september 2021",
    "note_info_third": "blitt flyttet til vår oversikt over gammel faktura.",
    "view_legacy_invoices": "Vis arkiverte fakturaer",
    "legacy_invoices": "Arkivert faktura",
    "legacy_invoices_info": "Se og last ned fakturaer betalt før 01.09.2021.",
    "legacy_invoice_date": "Faktureringsdato",
    "no_legacy_invoices": "Ingen tilgjengelige fakturaer",
    "no_more_legacy_invoices": "Ingen flere fakturaer tilgjengelig",
    "return_to_invoice_overview": "Tilbake til fakturaoversikten",
    "return_button_aria_label": "Klikk for å gå tilbake til fakturaoversikten",
    "infobox": "Fakturaer betalt før 01.09.2021 er arkivert."
  },

  "passings": {
    "passings": "Passeringer",
    "download_as_csv": "Last ned alle passeringer som CSV for gitt periode",
    "info": "Passeringsinformasjon",
    "checkpoint": "Sted",
    "time": "Tidspunkt",
    "price": "Pris",
    "no_passing": "Ingen tilgjengelig informasjon funnet",
    "no_available_passings": "Ingen passeringsinformasjon tilgjengelig",
    "total_results": "resultater totalt",
    "export_caution": "ADVARSEL. Nedlasting av store mengder passeringer påvirker ytelsen og kan ta et par minutter. Vennligst bruk med omhu.",
    "fill_filters": "Velg periode og kjøretøy"
  },

  "tag": {
    "tag": "Brikke",
    "battery_status": "Batteristatus",
    "tag_status": "Brikkestatus",
    "tag_number": "Brikkenummer",
    "agreement_number": "Avtalenummer",
    "expiration_date": "Utløpsdato",
    "order_box": "Eller velg å bestille en hel eske (120 stk)",
    "number_of_tags": "Antall brikker",
    "no_tag": "Ingen tilgjengelige brikker",
    "tag_details": {
      "vehicle_details": "Kjøretøydetaljer",
      "transfer_tag": "Flytt denne brikken",
      "edit_vehicle": "Rediger kjøretøy",
      "remove_vehicle": "Ta bort kjøretøy",
      "tag_details": "Brikkedetaljer"
    },
    "dialog": {
      "titles": {
        "success": "Suksess"
      },
      "subtitles": {
        "success": "Endringene dine er lagret. Så fort vi har godkjent endringene vil kjøretøyet bli oppdatert."
      }
    },
    "complete_order": "Fullfør bestilling",
    "completed_order": "Takk for din bestilling!",
    "max": "Maksgrense på",
    "registered": "Denne brikken er fortsatt aktiv på en eksiterende avtale. Trykk neste hvis du ønsker at brikken skal knyttes til en ny bil. Den eksisterende avtalen blir da inaktivert.",
    "available": "Brikken er tilgjengelig.",
    "loading": "laster...",
    "error_fetching_obe_details": "Det oppstod en feil ved henting av brikkedetailjer for å sette opp nedlastningsknapp for kjøretøysdokumentasjon. Prøv igjen senere."
  },

  "order_tag": {
    "order_tag": "Bestilling av brikke",
    "tag_order_completed": "Bestillingen din er registrert og bombrikke blir sendt til deg i posten.",
    "tag_order_warning_title": "Brikken din blir snart aktivert.",
    "tag_order_warning_subtitle": "Først etter at bestillingen er prosessert og den dukker opp i listen over avtaler kan du kjøre gjennom alle AUTOPASS bomstasjoner og få rabatt på passeringer!",
    "proceed_order_tag_first": "Brikkeabonnement belastes månedlig, i henhold til gjeldende pris når bombrikken registreres på et kjøretøy​.",
    "proceed_order_tag_second": "Rabatt gjelder fra bombrikken blir registrert i sentralsystemet, typisk 1-5 dager etter bestillingsdato",
    "terms_and_conditions": "Generelle og spesielle avtalebetingelser",
    "accept_terms_and_conditions": "Jeg har lest og akseptert vilkårene"
    },

  "transfer_tag": {
    "transfer_tag": "Flytt brikke",
    "bulk": {
      "documentation": "Dokumentasjon",
      "progress": {
        "initial": {
          "title": "Overfør brikken etter fil",
          "description": "Her kan du laste opp en fil for å overføre flere brikker samtidig. Vi refererer til følgende lenke for dokumentasjon:",
          "button": "Validere"
        },
        "valid": {
          "title": "Gyldig fil",
          "description": "brikke ble funnet, klare for overføring.",
          "button": "Bekrefte"
        },
        "invalid": {
          "title": "Ugyldig fil",
          "description": "Sjekk dokumentasjonen. Det var feil i filen på linje(r):"
        },
        "confirmed": {
          "title": "Brikke overført",
          "description": "brikke ble overført"
        },
        "error": {
          "title": "Feil",
          "description": "Noe gikk galt, sjekk dokumentasjonen, prøv igjen. Kontakt ellers kundesupport."
        }
      },
      "start_over": "Begynne på nytt"
    },
    "transfer_this_tag": "Flytt brikken til et nytt kjøretøy",
    "has_existing_tag": "Har du allerede en brikke?",
    "has_existing_tag_info": "Flytt brikken til nytt kjøretøy dersom du skal bruke en eksisterende brikke i et nytt kjøretøy.",
    "choose_tag_or_vehicle": "Velg brikke / kjøretøy",
    "transfer_complete": "Overføring fullført",
    "transfer_is_registered": "Flytting av brikke er registrert",
    "transfer_is_registered_info": "Informasjon om overføring",
    "transfer_is_registered_info_2": "Endringen trer i kraft når den er registrert i det nasjonale AutoPASS-systemet. Det kan ta inntil et døgn.",
    "belongs_to": "Tilhører i dag følgende kjøretøy",
    "removed_from": "Kjøretøy brikken blir fjernet fra",
    "new_vehicle": "Nytt kjøretøy for brikke"
  },

  "empty_tag": {
    "empty_tag": "Blanke brikker",
    "empty_tags_amount_lowercase": "blanke brikker",
    "one_box": "1 eske med brikker",
    "one_empty_tag_lowercase": "blank brikke",
    "order_empty_tags": "Bestill blanke brikker",
    "order_of_empty_tags": "Bestilling av blanke brikker",
    "empty_tags_description": "Når du bestiller blanke brikker vil du motta brikker fra oss uten at de er tilknyttet til et kjøretøy. Du kan selv registrere disse brikkene ved behov gjennom Min side.",
    "maximum_empty_tags": "Maksgrense på 120 brikker",
    "order_boxholder": "Bestill en eske",
    "empty_tags_order_completed": "Bestillingen din er registrert og bombrikker blir sendt til deg i posten.",
    "payment_deposit_info": "Brikkeabonnement belastes månedlig, i henhold til gjeldende pris når bombrikken registreres på et kjøretøy​."
  },

  "register_blank_tag": {
    "register_blank_tag": "Registrer blank brikke",
    "enter_tag_number": "Skriv inn brikkenr",
    "scan_tag": "Skann brikke",
    "barcode_scanner": "Strekkodeskanner",
    "registration_complete": "Registrering fullført",
    "registration_subtitle": "Registrering av kjøretøy på blanke brikker er fullført",
    "registration_info": "Avtalen er gyldig fra og med i morgen.  Når avtalen din er gyldig vil du kunne få rabatt på dine bompasseringer"
  },

  "unregister_vehicle": {
    "remove_vehicle": "Fjern kjøretøy",
    "choose_vehicle": "Velg et kjøretøy du ønsker å fjerne",
    "tag_options_title": "Hva ønsker du å gjøre med brikken?",
    "keep_and_reuse": "Behold brikke for senere bruk",
    "discard_tag": "Avslutt brikke",
    "removed_vehicle": "Kjøretøy er fjernet",
    "disclaimer": "Merk",
    "disclaimer_info": "Tollpasseringer kan ta opptil 24 timer å registrere i systemene våre. Saldoen din kan bli oppdatert etter at et kjøretøy er fjernet.",
    "discounts_info_subtitle": "Fordeler og rabatter blir fjernet",
    "discounts_info_info": "på bombrikker som ikke har registrert kjøretøy",
    "all_tags_removed_subtitle": "Om alle bombrikker på avtale er fjernet",
    "all_tags_removed_info": ", vil avtalen bli opphevet.",
    "deposit_info": "Hvis du velger å ikke gjenbruke bombrikken, oppfordrer vi deg til å sende den i retur til Postboks 393, 0103 Oslo.",
    "deposit_subtitle": "Eventuelt gjenstående depositum refunderes på neste faktura.",
    "throw_away_tags": "Brikker som ikke skal gjenbrukes kaster du i elektronisk avfall",
    "tag_option_title": "Valg for bombrikke"
  },

  "address": {
    "delivery_address_title": "Leveringsadresse",
    "delivery_address": "Adresse for levering av brikker",
    "another_address": "Annen adresse",
    "first_address": "Adresselinje 1",
    "second_address": "Adresselinje 2 - valgfritt",
    "postal_code": "Postnummer",
    "postal_city": "Poststed",
    "make_standard_delivery_address": "Gjør til min standard leveringsadresse",
    "standard_delivery_address_info": "Dette vil ikke endre din fakturaadresse",
    "no_address": "Ingen tilgjengelig adresse funnet",
    "country": "Land"
  },

  "status": {
    "new": "Ny",
    "shipped": "Sendt",
    "frozen": "Sperret",
    "lost": "Tapt",
    "active": "Aktiv",
    "pending": "Forespurt",
    "awaiting": "Ventende",
    "inactive": "Inaktiv",
    "on_hold": "Ønsket opprettet",
    "terminated": "Avsluttet",
    "awaiting_shipping": "Godkjent - venter på levering"
  },

  "invoice_status": {
    "pending": "Betaling etterspurt",
    "internal_debt_collection": "Betalingsoppfordring",
    "internal_debt_pending": "Venter på betalingsoppfordring",
    "external_debt_collection": "I ekstern inkassoprosess",
    "external_debt_pending": "Venter på ekstern inkasso",
    "debt_collection": "Inkasso",
    "paid": "Betalt",
    "payment_requested": "Betaling forespurt",
    "payment_failed": "Betaling feilet",
    "cancelled": "Kansellert",
    "credited": "Kredittert",
    "payment_revoked": "Betaling tilbakekalt",
    "payment_reminder_sent": "Inkassovarsel",
    "payment_reminder_pending": "Venter på inkassovarsel",
    "closed_by_debt_collector": "Inkassosak lukket",
    "credit_rejected": "Kredit avvist",
    "credit_pending": "Kreditering forespurt",
    "credit_note_new": "Ny kreditnota",
    "credit_note_cancelled": "Kreditnota kansellert",
    "credit_note_distribution_pending": "Kreditdistribuering venter",
    "credit_note_distributed": "Kreditnota distribuert",
    "credit_note_unknown_payment": "Ukjent betaling",
    "soft_reminder_sent": "Betalingspåminnelse sendt"
  },

  "customers": {
    "customer_number": "Kundenummer",
    "about_customers_title": "Klikk for å lese mer",
    "about_customer_profiles_part_1": "Her ser du en oversikt over de kundeforholdene som er knyttet til din BankID.",
    "about_customer_profiles_part_2": "I oversikten ser du dine private kundeforhold og kundeforhold for bedrifter som du har fullmakt til å administrere.",
    "about_customer_profiles_part_3": "Når du har valgt et kundeforhold kan du komme tilbake til oversikten ved å trykke på profilikonet oppe til høyre og velge Kundeforhold.",
    "warning_darkmode": "Det har blitt oppdaget at du bruker darkmode. For en bedre brukeropplevelse så anbefaler vi at du bruker lightmode.",
    "sparebank1_infobox": {
      "title": "SpareBank 1",
      "body": "Velg det private kundeforholdet du ønsker å fortsette med, eller opprett ett nytt dersom du ikke har et fra før."
    },
    "customer_profile_cards": {
      "private_title": "Kundeforhold privat",
      "company_title": "Kundeforhold bedrift",
      "no_associated_business": "Ingen bedrifter",
      "search": "Søk kundeforhold"
    },
    "add_action_cards": {
      "title": "Flere kundeforhold",
      "create_new_customer": "Opprett et nytt kundeforhold",
      "create_new_customer_info": "Opprett et nytt kundeforhold som privatkunde",
      "how_to_add_existing_company_profile_title": "Hvordan legge til eksisterende kundeforhold for bedrift?",
      "how_to_create_new_company_profile_title": "Hvordan opprette et nytt kundeforhold for bedrift",
      "how_to_sub_text": "Klikk her for å lese hvordan på"
    },
    "infobox": {
      "title": "Første gang du logger inn må du knytte kundeforhold du allerede har til din BankID.",
      "body": "Finn kundenummeret ditt på en tidligere faktura og velg '{{button}}'. Ta kontakt med oss hvis du trenger hjelp til å finne kundenummeret ditt.",
      "button": "Aktiver",
      "button_aria_label": "Trykk knapp for å legge til eksisterende kundeforhold"
    },
    "error_message_fetching_customers": "Et problem oppsto under henting av kundeforhold.",
    "error_message_fetching_users": "Klarte ikke hente eller lage bruker.",
    "activate_privilege_dialog": {
      "title": "Aktivér tilgang",
      "ok_btn": "Aktivér tilgang",
      "description": "Du har mottatt en invitasjon som gir deg tilgang til en kunde!",
      "customer_number_desc": "Du skal ha fått et kundenummer fra kunden som forsøker å gi deg tilgang. Skriv inn dette i feltet nedenfor.",
      "info_about_closing": "Dersom du lukker denne boksen kan du få den opp igjen ved å trykke på lenken du fikk på e-post.",
      "customer_number_error": "Kundenummer er påkrevd",
      "customer_number": "Kundenummer",
      "authorization_error": "En autorisasjonsfeil oppsto. Du kan ikke få tilgang til denne kunden.",
      "not_found_error": "Tilgang forspørselen har enten blitt brukt eller utløpt",
      "not_using_bankid": "For å aktiver tilgang på en norsk kunde må du være logget inn med BankID",
      "grant_token_expired": "Aktiver tilgang lenken er ikke lenger gyldig",
      "wrong_customer_number": "Feil kundenummer",
      "bankid_on_foreign_customer": "Utenlandske kunder kan ikke aktiveres når du er logget in med BankID",
      "birth_date_no_match": "Fødselsdato på kundeforholdet stemmer ikke overens med din BankID"
    },
    "recover_customer": {
      "customer_number": "Kundenummer",
      "registration_number": "Registreringsnummer",
      "button_header": "Aktiver kundeforhold du har fra før",
      "button_subtext": "Knytt kundeforhold du allerede har til denne innloggingen.",
      "page_title": "Tilgang til eksisterende kundeforhold",
      "submit_button_text": "Logg inn",
      "cancel_button_text": "Kanseller",
      "helper_text_header": "Når du logger deg inn første gang må du skrive inn kundenummer og bilens registreringsnummer",
      "customer_number_helper_text": "Du finner kundenummeret ditt i en tidligere faktura",
      "registration_number_helper_text": "Skriv inn bilens registreringsnummer. Hvis du har flere biler så skal du skrive inn registreringsnummeret på en av dem",
      "registration_number_invalid_text": "Verdien er ugyldig",
      "error_message": {
        "not_found": "Fant ikke brukerprofil. Vær oppmerksom på at angitt registreringsnummer må være tilknyttet angitt kundenummer.",
        "no_access": "Vi trenger mer informasjon for å knytte deg tilbrukerprofilen. Vennligst kontakt kundeservice."
      },
      "confirm_recover_button_text": "Neste"
    }
  },

  "profile": {
    "create_new_customer": "Opprett et nytt kundeforhold",
    "customer_profile": "Kundeforhold",
    "contact_info": "Mine kontaktopplysninger",
    "organization_name": "Organisasjonsnavn",
    "personal_info": "Personlig informasjon",
    "first_name": "Fornavn",
    "last_name": "Etternavn",
    "birth_date": "Fødselsdato",
    "email": "E-post",
    "billing_email": "Fakturering via e-post",
    "billing_email_description": "Du har valgt å godta distribusjon av fakturaer via e-post. Dette kan endres ved å kontakte kundeservice.",
    "edit_email": "Endre din e-postadresse",
    "new_email": "Ny e-postadresse",
    "confirm_new_email": "Bekreft ny e-postadresse",
    "current_email": "Nåværende e-postadresse",
    "mobile": "Mobil",
    "telephone": "Telefon",
    "phone_number_info": "Norske telefonnumre trenger kun åtte sifre.",
    "addresses": "Mine adresser",
    "customer_address": "Kundeadresse",
    "standard_billing_address": "Standard fakturaadresse",
    "edit_telephone": "Endre telefonnr.",
    "standard_invoice_address": "Standard fakturaadresse",
    "standard_delivery_address": "Standard leveringsadresse",
    "add_billing_address": "Legg til en egen adresse for faktura",
    "add_shipping_address": "Legg til en egen adresse for levering",
    "edit_address": "Endre din adresse",
    "edit_billing_address": "Endre din fakturaadresse",
    "edit_delivery_address": "Endre din leveringsadresse",
    "delivery_address": "Adresse for levering",
    "billing_address": "Adresse for faktura",
    "no_profile": "Ingen profil funnet",
    "no_email": "Ingen tilgjengelig informasjon om e-postadresse",
    "no_billing_email": "Ingen tilgjengelig informasjon om e-postadresse for fakturering",
    "no_address": "Ingen tilgjengelig informasjon om adresse",
    "no_telephone": "Ingen tilgjengelig informasjon om telefonnummer",
    "access_management": {
      "title": "Administrer tilgang",
      "no_content": "Ingen tilganger funnet. Dette er sannsynligvis en feil.",
      "description": "En oversikt over brukere som har tilgang til denne kunden. Tilgang betyr at de kan se og endre på kunden. Du kan fjerne og legge til tilganger. Når du gir noen tilgang må de aktivere tilgangen sin før de dukker opp i listen nedenfor.",
      "search": "Søk på navn eller e-post i listen",
      "privilege_dialog_reason_validation": "En forklaring er påkrevd",
      "add_privilege": {
        "button": "Gi tilgang",
        "dialog_title": "Gi tilgang",
        "dialog_description": "Legg til e-postadressen til brukeren som skal motta tilgangen. Brukeren vil ikke bli lagt inn i tilgangslisten før brukeren har fått e-post og bekreftet tilgangen sin.",
        "dialog_ok_button": "Send invitasjon",
        "dialog_cancel_button": "Avbryt",
        "reason": "Forklaring på hvorfor brukeren får tilgang."
      },
      "revoke_privilege": {
        "dialog_title": "Fjern tilgang",
        "dialog_description_title": "Er du sikker på at du vil fjerne denne tilgangen?",
        "dialog_description": "Brukeren vil få beskjed om dette og vil ikke lenger kunne se eller redigere kunden",
        "reason": "Forklaring på hvorfor brukerens tilgang fjernes.",
        "dialog_ok_button": "Fjern tilgang"
      }
    },
    "bank_reference": {
      "title": "Tilgang til passerings- og betalingsinformasjon",
      "description": "Nedenfor vises de samtykkene du har gitt til deling av passerings- og betalingsinformasjon. Du kan når som helst trekke tilbake samtykket.",
      "button": "Gi tilgang"
    }
  },

  "cases": {
    "cases": "Saker",
    "case": "Sak",
    "search_placeholder": "Søk opp saker etter saksnummer",
    "status": {
      "InProgress": "Under arbeid",
      "New": "Ny",
      "Paused": "Pauset",
      "Closed": "Lukket"
    },
    "no_case_found": "No case found",
    "page_info": "Hvis du har vært i kontakt med kundeservice, kan du slå opp saken her etter nummer for å se statusen",
    "info": "Nedenfor kan du se på sakene dine.",
    "button": "Mine saker"
  },

  "help": {
    "introduction": "På denne siden finner du informasjon om ofte stilte spørmål og hvordan du kan komme i kontakt med brukerstøtte.",
    "self_help": {
      "title": "Selvhjelp",
      "text": "For å finne svar på de hyppigst stilte spørsmålene og henvendelsene som brukerstøtte mottar anbefaler vi å sjekke ut sidene for Ofte stilte spørsmål.",
      "frequently_asked_questions_hyperlink_text": "Klikk her for å lese ofte stilte spørsmål"
    },
    "contact_support": {
      "title": "Kontakt brukerstøtte",
      "text": "Om du ikke finner svaret på det du lurer på i sidene for ofte stilte spørsmål kan du ta kontakt med brukerstøtte. Du kan velge mellom chat eller kontaktskjema.",
      "contact_page_hyperlink_text": "Klikk her for å bli videreført til 'Kontakt oss' siden"
    }
  },

  "error": {
    "something": "Noe gikk galt",
    "tag_order": "Noe gikk galt ved bestillingen av brikke for",
    "unregister": "Noe gikk galt ved avregistreringen av kjøretøy, dette kan skyldes at depositum ikke er betalt.",
    "register": "Noe gikk galt ved registrering av brikke(r)",
    "create_agreement": "Noe gikk galt ved oppretting av ny avtale",
    "authorization": "Tilgang nektet",
    "gotomainpage": "Gå til hovedsiden",
    "redirect_to_customer_profiles_page": "Videresendes til kundeforholdsiden om {{timeoutInSec}} sekunder..."
  }
}
