export const GREEN = '#499B23';
export const SUCCESS_GREEN = '#005F47';
export const RED = '#AA1F23';
export const YELLOW = '#FBAD16';
export const GRAY = '#6F6F6F';
export const WHITE = '#FFFFFF';
export const DARK_GRAY = '#3D3D3D';
export const MEDIUM_GRAY = '#D6D6D6';
export const LIGHT_GRAY = '#F9F9F9';
export const INFO_BLUE = '#000AFA';
export const BORDER_GRAY = '#B8B8B8';
export const VARDE = '#DED4C7';
export const LIGHT_VARDE = '#E5DDD3';
export const ACTIVE = '#A4D6B4';
export const INACTIVE = '#C8C5C3';
export const FROZEN = '#F26B67';
export const TERMINATED = '#000000';
export const AWAITING = '#F7941D';

// Autosync colors
export const AUTOSYNC_LIGHT_PURPLE = '#F6E8FF';
export const AUTOSYNC_BLACK = '#181818';
export const AUTOSYNC_DARK_PURPLE = '#450962';
